const n26 = ['AFM NL', 'AMF', 'Agence Nationale de la Sécurité des Systèmes d\'Information (ANSSI)', 'Agencia Española de Protección de Datos',
  'Associazione Bancaria Italiana', 'Autoriteit Persoonsgegevens', 'Autorité de contrôle prudentiel et de résolution (ACPR)', 'Autorité de la concurrence',
  'Banca d\'Italia', 'Banco de España', 'Banque de France', 'Boletín Oficial del Estado (BOE)', 'CNIL', 'CNMV', 'CONSOB', 'Comité Français d\'Organisation et de Normalisation Bancaires (CFONB)',
  'Direction Générale de la Concurrence, de la Consommation et de la Répression des Fraudes (DGCCRF)', 'FMA Austria', 'Fédération Bancaire Française',
  'Garante', 'Gazzetta Ufficiale della Repubblica Italiana', 'IVASS', 'Journal officiel de la République française (Legifrance)', 'Ministère de l\'Économie, des Finances et de la Relance',
  'Oesterreichische Nationalbank', 'Tracfin']

export default {
  accounting: ['AFME', 'BIS', 'EBA', 'ECB', 'EFRAG', 'FRC', 'ICAEW', 'IFRS', 'UK Finance'],
  clients: n26,
  dataprivacy: ['Agencia Española de Protección de Datos', 'Autoriteit Persoonsgegevens', 'CNIL',
    'Data Protection Commission Ireland', 'Datainspektionen',
    'Datatilsynet DK', 'Datatilsynet Norway', 'Garante', 'ICO', 'LGPD', 'Office of the Australian Information Commissioner',
    'Office of the Privacy Commissioner New Zealand', 'Office of the Information and Privacy Commissioner for British Columbia',
    'Office of the Privacy Commissioner of Canada', 'Personal Data Protection Commission Singapore', 'PPC Japan',
    'State Data Protection Inspectorate'],
  eucore: ['EBA', 'EC', 'ECB', 'ECB Banking Supervision', 'ECON', 'EIOPA', 'ESAS Joint Committee', 'ESMA', 'ESRB', 'KPMG Ireland'],
  intcore: ['BIS', 'FSB', 'IAIS', 'IOSCO', 'ISDA'],
  level1: ['BIS', 'BoE', 'EBA', 'EC', 'ECB', 'ECB Banking Supervision', 'ECON', 'EDPB', 'EIOPA', 'ESAS Joint Committee', 'ESMA', 'ESRB', 'FCA',
    'Financial Ombudsman Service', 'FSB', 'HM Treasury', 'IAIS', 'IOSCO', 'KPMG UK', 'KPMG Ireland', 'Pay UK', 'PRA', 'PSR', 'Pensions Regulator',
    'Treasury Select Committee'],
  level2: ['ABI', 'ACPR', 'AFM NL', 'AFME', 'AIC', 'AMF', 'ARRC', 'BaFin', 'Building Societies Association', 'CBI',
    'Chartered Banker Institute', 'CNMV', 'CSSF', 'DNB', 'EBF', 'ECSDA', 'EFAMA', 'European Capital Markets Institute',
    'European Mortgage Federation', 'European Payments Council', 'FIA', 'Finance Watch', 'FMLC', 'FMSB', 'FS-ISAC', 'GFMA',
    'GFXC', 'IA', 'ICMA', 'IIF', 'Institute of Economic Affairs', 'Insurance Europe', 'ISDA', 'Lending Standards Board',
    'Pensions Advisory Service', 'PIMFA', 'The City UK', 'TISA', 'UK Finance'],
  ukcore: ['BoE', 'FCA', 'Financial Ombudsman Service', 'HM Treasury', 'KPMG UK', 'Pay UK', 'PRA', 'PSR', 'Pensions Regulator', 'Treasury Select Committee'],
  uscore: ['CFTC', 'DFS', 'DOJ', 'FINRA', 'FRB', 'OCC', 'SEC']
}
